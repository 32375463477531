import React from 'react';

import styles from '../styles/stripe-input.module.scss';

const StripeInput = ({ Component, placeholder, onBlur, onFocus, onChange, stripeChange }) => {
    const localChange = (e) => {
        onChange(
            {
                target: {
                    value: e.empty ? '' : 'secure_value'
                }
            }
        );

        if (stripeChange) {
            stripeChange(e);
        }
    };
    return (
        <Component
            className={styles.input}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={localChange}
            placeholder={placeholder}
            style={{
                base: {
                    fontSize: '16px',
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    color: '#333333'
                },
                invalid: {
                    color: '#f44336'
                },
                empty: {
                    '::placeholder': {
                        color: '#DDDDDD'
                    }
                }
            }}
        />
    )
};

export default StripeInput;

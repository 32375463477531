import passwordValidator from 'password-validator';

export const validatePassword = (password) => {
    const schema = new passwordValidator();

    schema
        .is().min(6)
        .is().max(64)
        .has().not().spaces();

    const passErrs = schema.validate(password, { list: true });

    if (passErrs && passErrs.length) {
        const code = passErrs[0];
        let passDesc;

        switch (code) {
            case 'min':
                passDesc = 'Min 6 Characters';
                break;

            case 'max':
                passDesc = 'Max 64 Characters';
                break;

            case 'spaces':
                passDesc = 'Cannot Have Spaces';
                break;

            default:
                passDesc = 'Invalid Password';
                break;
        }

        return passDesc;
    }

    return null;
};

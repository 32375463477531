import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';
import _ from 'underscore';
import validator from 'validator';
import moment from 'moment';

import * as CheckoutActions from '../../actions/checkout-actions';

import styles from '../../styles/subscribe-form.module.scss';

class CustomizeStep extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			errors: null,
			inputErrors: {},
			values: {
				earrings: '',
				ring_size: '',
				discount_code: '',
				coupon: null,
				...props.formData
			}
		};

		this.updateCheckoutData = _.debounce(this.updateCheckoutData.bind(this), 500);
		this.handleChange = this.handleChange.bind(this);
		this.handleCodeChange = this.handleCodeChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (this.props.formData !== prevProps.formData) {
			this.setState(prev => {
				const {
					earrings,
					ring_size,
					discount_code,
					coupon
				} = prev.values;

				return {
					values: {
						...this.props.formData,
						earrings,
						ring_size,
						discount_code,
						coupon
					}
				};
			});
		}
	}

	updateCheckoutData() {
		this.props.setCheckoutData(
			{
				step: this.props.step,
				metadata: this.state.values
			}
		);
	}

	handleChange(e) {
		if (e.target) {
			const { name, value } = e.target;

			this.setState(
				state => ({
					values: {
						...state.values,
						[name]: value
					},
					inputErrors: {
						...state.inputErrors,
						[name]: null
					}
				}),
				this.updateCheckoutData
			);
		}
	}

	handleCodeChange(e) {
		if (e.target) {
			const { name, value } = e.target;

			const upper = value.replace(/[\W_]+/g, '').toUpperCase();

			this.setState(
				state => ({
					values: {
						...state.values,
						[name]: upper
					}
				}),
				this.updateCheckoutData
			);
		}
	}

	async onSubmit(e) {
		e.preventDefault();

		this.setState({ errors: null });

		const inputErrors = {};

		const { earrings, ring_size } = this.state.values;

		if (earrings === '') {
			inputErrors['earrings'] = 'Required';
		}

		if (ring_size === '') {
			inputErrors['ring_size'] = 'Required';
		}

		const errKeys = Object.keys(inputErrors);

		if (errKeys.length) {
			const firstKey = errKeys[0];

			const ele = document.getElementById(firstKey);

			if (ele) {
				ele.scrollIntoView({ behavior: 'smooth', block: 'center' });
			}

			this.setState({ inputErrors });
			return false;
		}

		this.setState({ loading: true });

		const { discount_code } = this.state.values;

		if (!validator.isEmpty(discount_code)) {
			const result = await this.props.getCoupon(
				{
					id: discount_code
				}
			);

			if (!result.ok) {
				await this.setState(
					state => ({
						loading: false,
						errors: ['Sorry, this discount code is not valid.'],
						values: {
							...state.values,
							coupon: null
						}
					}),
					this.updateCheckoutData
				);
				return false;
			}

			const { user } = this.props.auth;

			if (user && user.used_coupons && _.contains(user.used_coupons, discount_code)) {
				await this.setState(
					state => ({
						loading: false,
						errors: ['Sorry, this discount code is no longer available.'],
						values: {
							...state.values,
							coupon: null
						}
					}),
					this.updateCheckoutData
				);
				return false;
			}

			const coupon = result.data;

			const { redeem_by } = coupon;

			if (redeem_by) {
				const redeem_by_moment = moment.unix(redeem_by);
				const now = moment();

				if (now.isAfter(redeem_by_moment)) {
					await this.setState(
						state => ({
							loading: false,
							errors: ['Sorry, this discount code is expired.'],
							values: {
								...state.values,
								coupon: null
							}
						}),
						this.updateCheckoutData
					);
					return false;
				}
			}

			await this.setState(
				state => ({
					values: {
						...state.values,
						coupon
					}
				}),
				this.updateCheckoutData
			);
		} else {
			await this.setState(
				state => ({
					values: {
						...state.values,
						coupon: null
					}
				}),
				this.updateCheckoutData
			);
		}

		await this.props.submitStep(
			this.props.step,
			this.state.values
		);

		this.setState({ loading: false });

		return true;
	}

	render() {
		const {
			loading,
			errors,
			inputErrors,
			values
		} = this.state;

		return (
			<form
				noValidate
				className={styles.form}
				onSubmit={this.onSubmit}
				disabled={loading}
			>
				<section className={styles.section}>
					<div className={clsx(styles.top, styles.bm)}>
						<h6>Customize</h6>
					</div>
					<div className={styles.option}>
						<label htmlFor='earrings'>Do you want earrings?</label>
						<FormControl
							error={Boolean(inputErrors['earrings'])}
							classes={{
								root: styles.selector
							}}
						>
							<Select
								value={values.earrings}
								onChange={this.handleChange}
								inputProps={{
									name: 'earrings',
									id: 'earrings'
								}}
							>
								<MenuItem value={true}>Yes</MenuItem>
								<MenuItem value={false}>No</MenuItem>
							</Select>
							{inputErrors['earrings'] &&
								<FormHelperText>{inputErrors['earrings']}</FormHelperText>
							}
						</FormControl>
					</div>
					<div className={styles.option}>
						<label htmlFor='ring_size'>What's your ring size?</label>
						<FormControl
							error={Boolean(inputErrors['ring_size'])}
							classes={{
								root: styles.selector
							}}
						>
							<Select
								value={values.ring_size}
								onChange={this.handleChange}
								inputProps={{
									name: 'ring_size',
									id: 'ring_size'
								}}
							>
								<MenuItem value={'No Rings'}>No Rings</MenuItem>
								<MenuItem value={5}>5</MenuItem>
								<MenuItem value={6}>6</MenuItem>
								<MenuItem value={7}>7</MenuItem>
								<MenuItem value={8}>8</MenuItem>
								<MenuItem value={9}>9</MenuItem>
							</Select>
							{inputErrors['ring_size'] &&
								<FormHelperText>{inputErrors['ring_size']}</FormHelperText>
							}
						</FormControl>
					</div>
				</section>
				<section className={clsx(styles.section, styles.discount)}>
					<div className={styles.top}>
						<h6>Discount Code</h6>
					</div>
					{errors &&
						<ul id='discount_errors' className={styles.errors}>
							{errors.map((x, i) => (
								<li key={i}>{x}</li>
							))}
						</ul>
					}
					<TextField
						id='discount_code'
						name='discount_code'
						type='text'
						autoComplete='off'
						value={values.discount_code}
						onChange={this.handleCodeChange}
						margin='dense'
						variant='outlined'
						fullWidth={true}
						className={styles.input}
						inputProps={{
							className: styles.inner,
							maxLength: 12
						}}
					/>
					<p><small>Have a discount or referral code? Enter it here.</small></p>
				</section>
				<nav className={styles.nav}>
					<Button
						type='button'
						disabled={true}
					>
						Back
                    </Button>
					<Button
						type='submit'
						variant='contained'
						color='primary'
						disabled={loading}
						className={styles.submit}
					>
						{loading ?
							<CircularProgress
								color='inherit'
								size={20}
								className={styles.loading}
							/> :
							'NEXT'
						}
					</Button>
				</nav>
			</form>
		);
	}
}

CustomizeStep.props = {
	step: PropTypes.number.isRequired,
	total: PropTypes.number.isRequired,
	prevStep: PropTypes.func.isRequired,
	submitStep: PropTypes.func.isRequired,
	formData: PropTypes.object
};

const mapState = (state) => ({
	auth: state.auth
});

const mapDispatch = {
	setCheckoutData: CheckoutActions.setCheckoutData,
	getCoupon: CheckoutActions.getCoupon
};

export default connect(mapState, mapDispatch)(CustomizeStep);

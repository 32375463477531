import React, { useRef, useEffect, useState, } from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { HelpCircle } from 'react-feather';

import card_mono from 'payment-icons/min/mono/default.svg';
import card_outline from 'payment-icons/min/outline/default.svg';
import visa from 'payment-icons/min/flat/visa.svg';
import amex from 'payment-icons/min/flat/amex.svg';
import discover from 'payment-icons/min/flat/discover.svg';
import maestro from 'payment-icons/min/flat/maestro.svg';
import mastercard from 'payment-icons/min/flat/mastercard.svg';

import StripeInput from './stripe-input';

import styles from '../styles/stripe-input.module.scss';

const CardIcon = ({ brand, complete }) => {
    let src;

    switch (brand) {
        case 'visa':
            src = visa;
            break;

        case 'amex':
            src = amex;
            break;

        case 'discover':
            src = discover;
            break;

        case 'maestro':
            src = maestro;
            break;

        case 'mastercard':
            src = mastercard;
            break;

        default:
            src = complete ? card_mono : card_outline;
            break;
    }

    return (
        <img
            src={src}
            alt='Card Icon'
            className={styles.icon}
        />
    );
};

const StripeElementWrapper = ({ id, label, className, placeholder, icon = false, tooltip, Component }) => {
    const inputLabel = useRef(null);

    const [labelWidth, setLabelWidth] = React.useState(0);
    const [focused, setFocused] = useState(false);
    const [empty, setEmpty] = useState(true);
    const [error, setError] = useState(false);
    const [complete, setComplete] = useState(false);
    const [brand, setBrand] = useState(null);

    useEffect(() => {
        if (inputLabel.current.offsetWidth > 0) {
            setLabelWidth(inputLabel.current.offsetWidth);
        }
    }, []);

    useEffect(() => {
        if (inputLabel.current.offsetWidth > 0) {
            setLabelWidth(inputLabel.current.offsetWidth);
        }
    }, [focused]);

    const handleBlur = () => {
        setFocused(false);
    };

    const handleFocus = () => {
        setFocused(true);
    };

    const stripeChange = (e) => {
        setEmpty(e.empty);
        setError(e.error);
        setComplete(e.complete);

        if (icon) {
            setBrand(e.brand);
        }
    };

    const Adornment = () => {
        if (icon) {
            return (
                <InputAdornment
                    position='end'
                    disablePointerEvents={true}
                >
                    <CardIcon brand={brand} complete={complete} />
                </InputAdornment>
            );
        }

        if (tooltip) {
            return (
                <InputAdornment position='end'>
                    <Tooltip
                        title={tooltip}
                        interactive={true}
                        disableFocusListener={true}
                        enterTouchDelay={0}
                        leaveTouchDelay={5000}
                        placement='left'
                    >
                        <IconButton
                            aria-label='tooltip'
                            size='small'
                        >
                            <HelpCircle size={20} />
                        </IconButton>
                    </Tooltip>
                </InputAdornment>
            );
        }

        return null;
    }

    return (
        <FormControl
            variant='outlined'
            className={className}
            fullWidth={true}
            margin='dense'
            error={Boolean(error)}
        >
            <InputLabel
                ref={inputLabel}
                htmlFor={id}
                margin='dense'
                focused={focused}
                shrink={focused || !empty}
            >
                {label}
            </InputLabel>
            <OutlinedInput
                id={id}
                margin='dense'
                placeholder={focused ? placeholder : ''}
                labelWidth={labelWidth}
                onFocus={handleFocus}
                onBlur={handleBlur}
                inputComponent={StripeInput}
                inputProps={{
                    Component,
                    stripeChange
                }}
                endAdornment={<Adornment />}
            />
            {error &&
                <FormHelperText>{error.message}</FormHelperText>
            }
        </FormControl>
    );
};

export default StripeElementWrapper;

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'gatsby';
import Button from '@material-ui/core/Button';

import styles from '../../styles/subscribe-form.module.scss';

const CompletedStep = ({ auth }) => (
    <section className={styles.complete}>
        <h2>Order Complete</h2>
        <p>Thank you for subscribing!<br /><br />Now that you are a member, you get {auth.user && auth.user.discount_percent ? `${auth.user.discount_percent}% off` : 'a discount from'} our store!</p>
        <div className={styles.btns}>
            <Button
                variant='outlined'
                color='primary'
                component={Link}
                to='/my-account/'
                className={styles.btn}
            >
                View Your Account
            </Button>
            <Button
                variant='contained'
                color='primary'
                component={Link}
                to='/store/'
                className={styles.btn}
            >
                Check Out Our Store
            </Button>
        </div>
    </section>
);

CompletedStep.props = {
    step: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    submitStep: PropTypes.func.isRequired
};

const mapState = (state) => ({
    auth: state.auth
});

export default connect(mapState, null)(CompletedStep);

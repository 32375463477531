import React, { useRef, useEffect } from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';

const NativeSelect = ({ id, name, label, error, autoComplete, value, className, disabled, onChange, children }) => {
    const inputLabel = useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);

    useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    return (
        <FormControl
            variant='outlined'
            className={className}
            fullWidth={true}
            margin='dense'
            error={error}
            disabled={disabled}
        >
            <InputLabel
                ref={inputLabel}
                htmlFor={id}
                margin='dense'
            >
                {label}
            </InputLabel>
            <Select
                native
                value={value}
                onChange={onChange}
                disabled={disabled}
                input={
                    <OutlinedInput
                        id={id}
                        name={name}
                        margin='dense'
                        labelWidth={labelWidth}
                        autoComplete={autoComplete}
                    />
                }
            >
                {children}
            </Select>
        </FormControl>
    );
};

export default NativeSelect;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { injectStripe, CardNumberElement, CardExpiryElement, CardCvcElement } from 'react-stripe-elements';
import clsx from 'clsx';
import { Info, Lock } from 'react-feather';

import StripeElementWrapper from '../stripe-element-wrapper';

import * as CheckoutActions from '../../actions/checkout-actions';

import stripelogo from '../../images/powered-by-stripe.svg';

import styles from '../../styles/subscribe-form.module.scss';

class PaymentForm extends Component {
    constructor(props) {
        super(props);

        const hasDefaultCard = Boolean(props.auth.authenticated && props.account.defaultCard);
        const defaultCard = hasDefaultCard ? props.account.defaultCard : null;

        const hasExistingSubscriptions = Boolean(props.account.subscriptions.length > 0);

        this.state = {
            loading: false,
            errors: null,
            hasDefaultCard,
            useDefaultCard: hasDefaultCard,
            hasExistingSubscriptions,
            defaultCard,
            subscription: null
        };

        this.useDefaultCardChange = this.useDefaultCardChange.bind(this);
        this.scrollToErrors = this.scrollToErrors.bind(this);
        this.onBack = this.onBack.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    useDefaultCardChange(e) {
        const { checked } = e.target;

        this.setState({ useDefaultCard: checked });
    }

    disableDefaultCard() {
        this.setState({ defaultCard: false });
    }

    scrollToErrors() {
        const ele = document.getElementById('payment_errors');

        if (ele) {
            ele.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }

    onBack() {
        this.setState({ errors: null });
        this.props.prevStep();
    }

    async onSubmit(e) {
        e.preventDefault();

        this.setState({
            loading: true,
            errors: null
        });

        const { useDefaultCard } = this.state;

        let token = null;

        if (!useDefaultCard) {
            const tokenize = await this.props.stripe.createToken();

            if (tokenize.error) {
                this.setState(
                    {
                        loading: false,
                        errors: [tokenize.error.message]
                    },
                    this.scrollToErrors
                );

                return false;
            }

            token = tokenize.token;
        }

        const { subscription } = this.state;

        let result;

        if (subscription) {
            result = await this.props.subscriptionPayment(
                {
                    subscription,
                    token
                }
            );
        } else {
            const { plan } = this.props;

            const {
                coupon,
                earrings,
                ring_size
            } = this.props.formData;

            const metadata = { earrings, ring_size };

            result = await this.props.createSubscription(
                {
                    plan,
                    token,
                    coupon,
                    metadata
                }
            );
        }

        const { ok, error, subscription_id } = result;

        if (ok) {
            await this.props.submitStep(
                this.props.step,
                this.props.formData
            );

            this.setState({ loading: false });

            return true;
        }

        const keepDefault = error && error.type === 'coupon_unavailable';
        const message = error && error.message ? error.message : 'Sorry, an error occurred.';

        this.setState(
            {
                loading: false,
                errors: [
                    message,
                    'Please try again.'
                ],
                useDefaultCard: keepDefault,
                subscription: subscription_id
            },
            this.scrollToErrors
        );

        return false;
    }

    render() {
        const {
            loading,
            errors,
            hasDefaultCard,
            useDefaultCard,
            hasExistingSubscriptions,
            defaultCard
        } = this.state;

        return (
            <form
                noValidate
                className={styles.form}
                onSubmit={this.onSubmit}
                disabled={loading}
            >
                <section className={styles.section}>
                    <div className={clsx(styles.top, styles.mm)}>
                        <h5>Payment</h5>
                        <span><Lock size={16} className={styles.icon} /> <small>All transactions are secure and encrypted.</small></span>
                    </div>
                    {hasDefaultCard &&
                        <div className={styles.switch}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        name='use_default_card'
                                        checked={useDefaultCard}
                                        onChange={this.useDefaultCardChange}
                                    />
                                }
                                label={`Use Saved Card (${defaultCard.brand} ending in ${defaultCard.last4})`}
                                classes={{
                                    label: styles.label
                                }}
                            />
                            {!useDefaultCard && hasExistingSubscriptions &&
                                <p><Info size={16} className={styles.icon} /> Please note that if you have any active subscriptions, this will update their payment method.</p>
                            }
                        </div>
                    }
                    {errors &&
                        <ul id='payment_errors' className={styles.errors}>
                            {errors.map((x, i) => (
                                <li key={i}>{x}</li>
                            ))}
                        </ul>
                    }
                    {!useDefaultCard &&
                        <>
                            <StripeElementWrapper
                                id='card_number'
                                label='Card Number'
                                placeholder='1234 1234 1234 1234'
                                className={clsx(styles.input, styles.mb)}
                                icon={true}
                                Component={CardNumberElement}
                            />
                            <div className={clsx(styles.row2, styles.mobilefw)}>
                                <div className={styles.cell}>
                                    <StripeElementWrapper
                                        id='card_expiry'
                                        label='Expiration (MM / YY)'
                                        placeholder='06 / 24'
                                        className={styles.input}
                                        Component={CardExpiryElement}
                                    />
                                </div>
                                <div className={styles.cell}>
                                    <StripeElementWrapper
                                        id='card_cvc'
                                        label='Security Code (CVC)'
                                        placeholder='123'
                                        className={styles.input}
                                        tooltip='A 3-digit security code usually found on the back of your card. American Express cards have a 4-digit code located on the front.'
                                        Component={CardCvcElement}
                                    />
                                </div>
                            </div>
                        </>
                    }
                    <p className={styles.disclaimer}>
                        <small>
                            {'By clicking submit you are agreeing to our '}
                            <a href='/shipping-and-returns/' target='_blank' rel='noopener noreferrer'>Return Policy</a>
                            {', '}
                            <a href='/privacy-policy/' target='_blank' rel='noopener noreferrer'>Privacy Policy</a>
                            {', and '}
                            <a href='/terms-and-conditions/' target='_blank' rel='noopener noreferrer'>Terms and Conditions</a>
                            {'.'}
                        </small>
                    </p>
                    <div className={styles.stripelogo}>
                        <p><small>Payments securely handled via Stripe.</small></p>
                        <img src={stripelogo} alt='Powered by Stripe' />
                    </div>
                </section>
                <nav className={styles.nav}>
                    <Button
                        type='button'
                        disabled={loading}
                        onClick={this.onBack}
                    >
                        Back
                    </Button>
                    <Button
                        type='submit'
                        variant='contained'
                        color='primary'
                        disabled={loading}
                        className={styles.submit}
                    >
                        {loading ?
                            <CircularProgress
                                color='inherit'
                                size={20}
                                className={styles.loading}
                            /> :
                            'SUBMIT'
                        }
                    </Button>
                </nav>
            </form>
        );
    }
}

PaymentForm.props = {
    step: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    prevStep: PropTypes.func.isRequired,
    submitStep: PropTypes.func.isRequired,
    formData: PropTypes.object,
    plan: PropTypes.object
};

const mapState = (state) => ({
    auth: state.auth,
    account: state.account
});

const mapDispatch = {
    createSubscription: CheckoutActions.createSubscription,
    subscriptionPayment: CheckoutActions.subscriptionPayment
};

export default connect(mapState, mapDispatch)(
    injectStripe(PaymentForm)
);

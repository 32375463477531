import React, { Component } from 'react';
import { connect } from 'react-redux';
import { graphql } from 'gatsby';
import CircularProgress from '@material-ui/core/CircularProgress';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import clsx from 'clsx';
import _ from 'underscore';
import qs from 'query-string';
import { ShoppingCart, ChevronDown, ChevronUp } from 'react-feather';

import SEO from '../../components/seo';
import { formatPrice } from '../../utils/format-number';
import { TAX_CONFIG } from '../../constants/app';

import CustomizeStep from '../../components/checkout/customize-step';
import ShippingInfoStep from '../../components/checkout/shipping-info-step';
import PaymentStep from '../../components/checkout/payment-step';
import CompletedStep from '../../components/checkout/completed-step';

import * as AuthActions from '../../actions/auth-actions';
import * as AccountActions from '../../actions/account-actions';
import * as CheckoutActions from '../../actions/checkout-actions';

import styles from '../../styles/subscribe.module.scss';
//import buttonStyles from '../../styles/buttons.module.scss';

const steps = [
	{
		title: 'Customize',
		component: CustomizeStep
	},
	{
		title: 'Shipping Info',
		component: ShippingInfoStep
	},
	{
		title: 'Payment',
		component: PaymentStep
	},
	{
		title: 'Complete',
		component: CompletedStep
	}
];

//const customizeStep = 0;
//const shippingStep = 1;
const paymentStep = 2;
const finalStep = 3;

class SubscribePage extends Component {
	constructor(props) {
		super(props);

		const {
			product_id,
			product,
			plan_id,
			plan,
			discount_code
		} = this.getProductPlanFromProps(props);

		this.state = {
			loading: !props.auth.initialized || props.auth.authenticated,
			product,
			plan,
			discount: null,
			taxes: null,
			total: 0,
			step: props.checkout.step,
			formData: {
				...props.checkout.metadata,
				product,
				plan
			},
			discount_code,
			redirect: {
				pathname: props.location.pathname,
				state: {
					product_id,
					plan_id
				}
			},
			expandMeta: false,
			collapseHeight: null,
			anchorEl: null
		};

		this.collapseTimeout = null;

		this.collapseInnerRef = React.createRef();

		this.getAccountDetails = this.getAccountDetails.bind(this);
		this.onToggleMeta = this.onToggleMeta.bind(this);
		this.openChangePlan = this.openChangePlan.bind(this);
		this.closeChangePlan = this.closeChangePlan.bind(this);
		this.onPlanChange = this.onPlanChange.bind(this);
		this.prevStep = this.prevStep.bind(this);
		this.nextStep = this.nextStep.bind(this);
		this.submitStep = this.submitStep.bind(this);
		this.refreshUser = this.refreshUser.bind(this);
	}

	getProductPlanFromProps(props) {
		const { state, search } = props.location;

		if (!state) {
			// product and plan was not passed in location state
			// pick default product and plan
			const product = props.data.allSubscriptionsJson.edges[0].node;
			const plan = product.plans[0];

			const params = search ? qs.parse(search) : {};

			return {
				product_id: product.id,
				product,
				plan_id: plan.id,
				plan,
				discount_code: params.discount_code || null
			};
		}

		const { product_id, plan_id } = state;

		const subEdge = _.findWhere(
			props.data.allSubscriptionsJson.edges,
			(x) => (x.node.id === product_id)
		);

		const product = subEdge.node;

		const plan = _.findWhere(
			product.plans,
			{
				'id': plan_id
			}
		);

		const params = search ? qs.parse(search) : {};

		return {
			product_id,
			product,
			plan_id,
			plan,
			discount_code: params.discount_code || state.discount_code || null
		};
	}

	async componentDidMount() {
		await this.getAccountDetails();
		await this.initDiscount();
		await this.checkDiscount();
		await this.checkTaxes();
		await this.calcTotal();
	}

	async componentDidUpdate(prevProps) {
		if (
			this.props.auth.initialized !== prevProps.auth.initialized ||
			this.props.auth.authenticated !== prevProps.auth.authenticated
		) {
			await this.getAccountDetails();
		}

		if (this.props.account.shipping !== prevProps.account.shipping) {
			await this.checkDiscount();
			await this.checkTaxes();
			await this.calcTotal();
		}
	}

	async getAccountDetails() {
		if (!this.props.auth.initialized) {
			return;
		}

		if (!this.props.auth.authenticated) {
			await this.setState({ loading: false });
			return;
		}

		await this.props.getAccountDetails();
		await this.setState({ loading: false });
	}

	async initDiscount() {
		const {
			discount_code,
			formData
		} = this.state;

		if (discount_code) {
			this.setState({ loading: true });

			const result = await this.props.getCoupon(
				{
					id: discount_code
				}
			);

			let apply_code = true;

			if (!result.ok) {
				apply_code = false;
				return;
			}

			const { user } = this.props.auth;

			if (user && user.used_coupons && _.contains(user.used_coupons, discount_code)) {
				apply_code = false;
				return;
			}

			if (apply_code) {
				const coupon = result.data;

				formData['discount_code'] = discount_code;
				formData['coupon'] = coupon;
			} else {
				formData['discount_code'] = '';
				formData['coupon'] = null;
			}

			this.setState({
				loading: false,
				formData
			});

			this.props.setCheckoutData(
				{
					step: this.state.step,
					metadata: formData
				}
			);
		}
	}

	async checkDiscount() {
		const { coupon } = this.state.formData;

		if (coupon) {
			const {
				id,
				amount_off,
				percent_off
			} = coupon;

			await this.setState({
				discount: {
					id,
					amount_off,
					percent_off
				}
			});
		} else if (this.state.discount) {
			await this.setState({ discount: null });
		}
	}

	async checkTaxes() {
		let state;

		const { formData } = this.state;
		const { shipping } = this.props.account;

		if (formData.state) {
			state = formData.state;
		} else if (shipping && shipping.state) {
			state = shipping.state;
		} else {
			if (this.state.taxes) {
				await this.setState({ taxes: null });
			}
			return;
		}

		const taxes = _.findWhere(
			TAX_CONFIG,
			{
				state
			}
		);

		await this.setState({ taxes });
	}

	async calcTotal() {
		let total = 0;

		const { plan, discount, taxes } = this.state;

		if (plan) {
			total += plan.total;
		}

		if (discount) {
			let discount_amount = 0;

			if (typeof (discount.amount_off) === 'number') {
				discount_amount = discount.amount_off / 100;
			} else if (typeof (discount.percent_off) === 'number') {
				discount_amount = total * discount.percent_off / 100;
			}

			discount.amount = discount_amount;

			total -= discount_amount;
		}

		if (taxes) {
			const tax_amount = total * taxes.percent / 100;

			taxes.amount = tax_amount;

			total += tax_amount;
		}

		await this.setState({
			taxes,
			discount,
			total
		});
	}

	onToggleMeta() {
		if (this.collapseTimeout) {
			clearTimeout(this.collapseTimeout);
		}

		const open = Boolean(!this.state.expandMeta);

		let height = 300;

		if (this.collapseInnerRef && this.collapseInnerRef.current) {
			height = this.collapseInnerRef.current.offsetHeight;
		}

		this.setState(
			{
				collapseHeight: open ? 0 : height
			},
			() => {
				setTimeout(
					() => {
						this.setState({
							expandMeta: open,
							collapseHeight: open ? height : 0
						});

						this.collapseTimeout = setTimeout(
							() => {
								this.collapseTimeout = null;
								this.setState({ collapseHeight: null });
							},
							300
						);
					},
					50
				);
			}
		);
	}

	openChangePlan(e) {
		this.setState({ anchorEl: e.currentTarget });
	}

	closeChangePlan() {
		this.setState({ anchorEl: null });
	}

	async onPlanChange(plan) {
		const { pathname } = this.props.location;

		await this.setState(state => ({
			plan,
			formData: {
				...state.formData,
				plan
			},
			redirect: {
				pathname,
				state: {
					product_id: state.product.id,
					plan_id: plan.id
				}
			},
			anchorEl: null
		}));

		await this.calcTotal();
	}

	prevStep() {
		this.setState(state => ({ step: state.step - 1 }));

		if (typeof window !== 'undefined') {
			window.scrollTo(0, 0);
		}
	}

	nextStep() {
		this.setState(state => ({ step: state.step + 1 }));

		if (typeof window !== 'undefined') {
			window.scrollTo(0, 0);
		}
	}

	async submitStep(step, data) {
		// Customize & shipping info steps
		if (step < paymentStep) {
			this.props.setCheckoutData({
				step: (step + 1),
				metadata: data
			});

			await this.setState({ formData: data });

			await this.checkDiscount();
			await this.checkTaxes();
			await this.calcTotal();
		}

		// payment step done - checkout complete
		if (step === paymentStep) {
			await this.props.clearCheckoutData();

			// load account details with new subscription
			// in case they click on view my account
			// also get new user details with active_subscriber flag
			if (this.props.auth.authenticated) {
				await this.refreshUser();
			}

			// fire custom google tag manager event
			if (window.dataLayer && typeof (window.dataLayer.push) === 'function') {
				const { total, plan } = this.state;

				window.dataLayer.push({
					'event': 'subscribe-complete',
					'category': 'subscribe',
					'action': plan && plan.title ? `Plan: ${plan.title}` : 'complete',
					'label': 'Subscribe Complete',
					'value': total
				});
			}
		}

		// go to next step
		if (step < finalStep) {
			this.nextStep();
		}
	}

	async refreshUser() {
		await this.props.checkAuth();
		await this.props.getAccountDetails();
	}

	render() {
		return (
			<>
				<SEO
					title='Subscribe'
					description='Complete checkout to start your subscription and order your first shipment.'
					path={'/checkout/subscribe/'}
				/>
				{this.renderContent()}
			</>
		);
	}

	renderContent() {
		if (this.state.loading) {
			return (
				<section className={clsx(styles.main, styles.loading)}>
					<CircularProgress size={48} />
				</section>
			);
		}

		const {
			product,
			plan,
			discount,
			taxes,
			total,
			step,
			formData,
			redirect,
			expandMeta,
			collapseHeight,
			anchorEl
		} = this.state;

		return (
			<section className={styles.main}>
				<div className={styles.content}>
					<h1>Subscribe</h1>
					<div className={styles.checkout}>
						<div className={styles.panel}>
							<Stepper
								activeStep={step}
								classes={{
									root: styles.stepper
								}}
							>
								{steps.map((x, i) => {
									if (i === finalStep) {
										return null;
									}

									const active = Boolean(i === step);
									const completed = Boolean(i < step);

									return (
										<Step
											key={i}
											completed={completed}
											classes={{
												root: styles.step
											}}
										>
											<StepLabel
												classes={{
													root: clsx(styles.steplabel, active ? styles.active : null),
													label: styles.label,
													iconContainer: styles.iconContainer
												}}
											>
												{x.title}
											</StepLabel>
										</Step>
									);
								})}
							</Stepper>
							{steps.map((x, i) => {
								const hidden = Boolean(i !== step);
								const Component = x.component;

								return (
									<div
										key={i}
										role='tabpanel'
										tabIndex={0}
										hidden={hidden}
										className={styles.tabpanel}
									>
										<Component
											step={step}
											total={steps.length}
											prevStep={this.prevStep}
											submitStep={this.submitStep}
											formData={formData}
											plan={plan}
											redirect={redirect}
										/>
									</div>
								)
							})}
						</div>
						<aside className={styles.meta}>
							<div className={styles.box}>
								<button
									type='button'
									className={clsx(styles.toggle, expandMeta ? styles.active : null)}
									onClick={this.onToggleMeta}
								>
									<span className={styles.wrap}>
										<span className={styles.label}>
											<ShoppingCart size={20} className={styles.icon} />
											{'Your subscription'}
											{expandMeta ?
												<ChevronUp size={20} className={styles.caret} /> :
												<ChevronDown size={20} className={styles.caret} />
											}
										</span>
										<span className={styles.total}>{formatPrice(total)}</span>
									</span>
								</button>
								<div
									className={clsx(styles.collapse, expandMeta ? styles.out : null)}
									style={{ height: collapseHeight }}
								>
									<div
										ref={this.collapseInnerRef}
										className={styles.inner}
									>
										<h5 className={styles.title}>
											<ShoppingCart size={20} className={styles.icon} />
											{'Your Subscription'}
										</h5>
										{product && plan &&
											<div className={styles.subinfo}>
												<h5>{product.title}</h5>
												<div className={styles.plan}>
													<h6>{plan.title}</h6>
													<p>
														{formatPrice(plan ? plan.price : 0)} / month<br />
														{plan.save > 0 &&
															<>
																<span className={styles.save}>{formatPrice(plan.save)} total savings</span><br />
															</>
														}
														{plan.description}
														{plan.storeDiscount &&
															<>
																<br /><span className={styles.discount}>Includes a {plan.storeDiscount}% discount to our store</span>
															</>
														}
													</p>
													{/*
                                                    {step < finalStep &&
                                                        <button
                                                            type='button'
                                                            aria-controls='plan-menu'
                                                            aria-haspopup='true'
                                                            className={clsx(buttonStyles.link, styles.changebtn)}
                                                            onClick={this.openChangePlan}
                                                        >
                                                            Change Plan
                                                        </button>
													}
													*/}
													<Menu
														id='plan-menu'
														anchorEl={anchorEl}
														keepMounted
														open={Boolean(anchorEl)}
														onClose={this.closeChangePlan}
													>
														{product.plans.map((x) => (
															<MenuItem
																key={x.id}
																onClick={() => this.onPlanChange(x)}
															>
																{x.title}
															</MenuItem>
														))}
													</Menu>
												</div>
											</div>
										}
										<ul>
											<li>
												<h6>{plan.title}</h6>
												<span>{formatPrice(plan ? plan.total : 0)}</span>
											</li>
											{Boolean(discount) &&
												<li className={styles.green}>
													<h6>FIRST MONTH PROMO</h6>
													<span>{'-'}{formatPrice(discount.amount ? discount.amount : 0)}</span>
												</li>
											}
											<li>
												<h6>SHIPPING</h6>
												<span>FREE</span>
											</li>
											{Boolean(taxes) &&
												<li>
													<h6>{taxes.state} SALES TAX</h6>
													<span>{formatPrice(taxes.amount ? taxes.amount : 0)}</span>
												</li>
											}
											<li className={styles.total}>
												<h6>TOTAL</h6>
												<span>
													{formatPrice(total)}
												</span>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</aside>
					</div>
				</div>
			</section>
		);
	}
}

export const query = graphql`
    query {
        allSubscriptionsJson {
            edges {
                node {
                    id
                    title
                    handle
                    productType
                    vendor
                    descriptionHtml
                    plans {
                        id
                        title
                        description
                        interval
                        price
                        total
                        save
                        storeDiscount
                    }
                    images {
                        alt
                        src {
                            childImageSharp {
                                main: fluid(quality: 90, maxWidth: 4096) {
                                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                }
                                thumb: fixed(quality: 90, width: 60) {
                                    ...GatsbyImageSharpFixed_withWebp_tracedSVG
                                }
                                ogimage: fixed(quality: 90, width: 1200, height: 630, cropFocus: CENTER) {
                                    ...GatsbyImageSharpFixed
                                }
                            }
                        }
                    }
                }
            }
        }                
    }
`;

const mapState = (state) => ({
	auth: state.auth,
	checkout: state.checkout,
	account: state.account
});

const mapDispatch = {
	checkAuth: AuthActions.checkAuth,
	getAccountDetails: AccountActions.getAccountDetails,
	setCheckoutData: CheckoutActions.setCheckoutData,
	clearCheckoutData: CheckoutActions.clearCheckoutData,
	getCoupon: CheckoutActions.getCoupon
};

export default connect(mapState, mapDispatch)(SubscribePage);

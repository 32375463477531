import React from 'react';
import PropTypes from 'prop-types';
import { Elements } from 'react-stripe-elements';

import PaymentForm from './payment-form';

const PaymentStep = (props) => (
    <Elements>
        <PaymentForm {...props} />
    </Elements>
);

PaymentStep.props = {
    step: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    prevStep: PropTypes.func.isRequired,
    submitStep: PropTypes.func.isRequired,
    formData: PropTypes.object
};

export default PaymentStep;

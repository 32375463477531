import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { Link } from 'gatsby';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import validator from 'validator';
import { Info } from 'react-feather';
import _ from 'underscore';

import { validatePassword } from '../../utils/account-utils';

import * as CheckoutActions from '../../actions/checkout-actions';
import * as AccountActions from '../../actions/account-actions';
import * as Errors from '../../constants/errors';

import NativeSelect from '../native-select';

import styles from '../../styles/subscribe-form.module.scss';

class ShippingInfoStep extends Component {
    constructor(props) {
        super(props);

        const hasSavedAddress = Boolean(props.auth.authenticated && props.account.shipping);

        const savedAddress = hasSavedAddress ?
            {
                fname: props.account.fname || '',
                lname: props.account.lname || '',
                ...props.account.shipping
            } : {};

        let values = {
            email: '',
            password: '',
            subscribe_newsletter: true,
            use_saved_address: true,
            fname: '',
            lname: '',
            addressln1: '',
            addressln2: '',
            city: '',
            state: '',
            postal: ''
        };

        if (props.formData['use_saved_address']) {
            values = {
                ...values,
                ...props.formData,
                ...savedAddress
            };
        } else {
            values = {
                ...values,
                ...savedAddress,
                ...props.formData,
            };
        }

        const useSavedAddress = Boolean(hasSavedAddress && values['use_saved_address']);

        const hasExistingSubscriptions = Boolean(props.account.subscriptions.length > 0);

        this.state = {
            loading: false,
            newAccount: !props.auth.authenticated,
            hasSavedAddress,
            useSavedAddress,
            savedAddress,
            hasExistingSubscriptions,
            errors: null,
            inputErrors: {},
            values
        };

        this.updateCheckoutData = _.debounce(this.updateCheckoutData.bind(this), 500);
        this.handleChange = this.handleChange.bind(this);
        this.checkChange = this.checkChange.bind(this);
        this.useSavedAddressChange = this.useSavedAddressChange.bind(this);
        this.scrollToErrors = this.scrollToErrors.bind(this);
        this.onBack = this.onBack.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.formData !== prevProps.formData) {
            this.setState(prev => {
                const {
                    email,
                    password,
                    subscribe_newsletter,
                    use_saved_address,
                    fname,
                    lname,
                    addressln1,
                    addressln2,
                    city,
                    state,
                    postal
                } = prev.values;

                return {
                    values: {
                        ...this.props.formData,
                        email,
                        password,
                        subscribe_newsletter,
                        use_saved_address,
                        fname,
                        lname,
                        addressln1,
                        addressln2,
                        city,
                        state,
                        postal
                    }
                };
            });
        }
    }

    updateCheckoutData() {
        this.props.setCheckoutData(
            {
                step: this.props.step,
                metadata: this.state.values
            }
        );
    }

    handleChange(e) {
        if (e.target) {
            const { name, value } = e.target;

            this.setState(
                state => ({
                    values: {
                        ...state.values,
                        [name]: value
                    },
                    inputErrors: {
                        ...state.inputErrors,
                        [name]: null
                    }
                }),
                this.updateCheckoutData
            );
        }
    }

    checkChange(e) {
        if (e.target) {
            const { name, checked } = e.target;

            this.setState(
                state => ({
                    values: {
                        ...state.values,
                        [name]: checked
                    }
                }),
                this.updateCheckoutData
            );
        }
    };

    useSavedAddressChange(e) {
        const { checked } = e.target;

        const newState = {
            useSavedAddress: checked,
            values: {
                ...this.state.values,
                use_saved_address: checked
            }
        };

        if (checked) {
            newState.values = {
                ...newState.values,
                ...this.state.savedAddress
            };
        }

        this.setState(
            newState,
            this.updateCheckoutData
        );
    }

    scrollToErrors() {
        const ele = document.getElementById('shipping_errors');

        if (ele) {
            ele.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }

    onBack() {
        this.setState({
            errors: null,
            inputErrors: {}
        });
        this.props.prevStep();
    }

    async onSubmit(e) {
        e.preventDefault();

        const { newAccount } = this.state;

        this.setState({ errors: null });

        if (!newAccount && this.state.useSavedAddress) {
            this.setState({ loading: true });

            await this.props.submitStep(
                this.props.step,
                this.state.values
            );

            this.setState({ loading: false });

            return true;
        }

        const required = [
            {
                key: 'email',
                label: 'Email',
                ignore: !newAccount
            },
            {
                key: 'password',
                label: 'Password',
                ignore: !newAccount
            },
            {
                key: 'fname',
                label: 'First Name'
            },
            {
                key: 'lname',
                label: 'Last Name'
            },
            {
                key: 'addressln1',
                label: 'Address'
            },
            {
                key: 'city',
                label: 'City'
            },
            {
                key: 'state',
                label: 'State'
            },
            {
                key: 'postal',
                label: 'ZIP Code'
            }
        ];

        const inputErrors = {};

        for (let i = 0; i < required.length; i++) {
            const x = required[i];

            if (x.ignore) continue;

            if (!this.state.values[x.key]) {
                inputErrors[x.key] = `${x.label} Required`;
            }
        }

        const {
            email,
            password,
            fname,
            lname,
            subscribe_newsletter
        } = this.state.values;

        if (newAccount && email && !validator.isEmail(email)) {
            inputErrors['email'] = 'Invalid Email';
        }

        if (newAccount && password) {
            const passErr = validatePassword(password);

            if (passErr) {
                inputErrors['password'] = passErr;
            }
        }

        const errKeys = Object.keys(inputErrors);

        if (errKeys.length) {
            const firstKey = errKeys[0];

            const ele = document.getElementById(firstKey);

            if (ele) {
                ele.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }

            this.setState({ inputErrors });
            return false;
        }

        this.setState({ loading: true });

        if (newAccount) {
            const signupResult = await this.props.signup(
                {
                    email,
                    password,
                    fname,
                    lname,
                    subscribe_newsletter,
                    persistent: true
                }
            );

            if (!signupResult.ok) {
                let errors;

                const { type } = signupResult.error;

                switch (type) {
                    case Errors.EMAIL_TAKEN:
                        errors = [
                            'An account with that email already exists.',
                            'Please login instead.'
                        ];
                        break;

                    case Errors.INVALID_EMAIL:
                        errors = [
                            'Sorry, this email may be invalid or unreachable.',
                            'Please try again with a different email.'
                        ];
                        break;

                    default:
                        errors = [
                            'Sorry, an error occurred.',
                            'Please try again.'
                        ];
                        break;
                }

                this.setState(
                    {
                        loading: false,
                        errors
                    },
                    this.scrollToErrors
                );

                return false;
            }
        }

        const {
            addressln1,
            addressln2,
            city,
            state,
            postal
        } = this.state.values;

        const setAddressResult = await this.props.setShippingAddress(
            {
                fname,
                lname,
                addressln1,
                addressln2,
                city,
                state,
                postal
            }
        );

        if (!setAddressResult.ok) {
            this.setState(
                {
                    loading: false,
                    newAccount: false,
                    hasSavedAddress: false,
                    useSavedAddress: false,
                    errors: [
                        'Sorry, an error occurred.',
                        'Please try again.'
                    ]
                },
                this.scrollToErrors
            );

            return false;
        }

        await this.props.submitStep(
            this.props.step,
            this.state.values
        );

        this.setState({
            loading: false,
            newAccount: false,
            hasSavedAddress: true,
            useSavedAddress: true,
        });

        return true;
    }

    render() {
        const {
            loading,
            newAccount,
            hasSavedAddress,
            useSavedAddress,
            hasExistingSubscriptions,
            errors,
            inputErrors,
            values
        } = this.state;

        return (
            <form
                noValidate
                className={styles.form}
                onSubmit={this.onSubmit}
                disabled={loading}
            >
                {newAccount &&
                    <section className={styles.section}>
                        <div className={styles.top}>
                            <h6>Create an Account</h6>
                            <span>
                                {'Already have an account? '}
                                <Link
                                    to='/login/'
                                    state={
                                        {
                                            redirect: this.props.redirect
                                        }
                                    }
                                >
                                    Log in
                                </Link>
                            </span>
                        </div>
                        {errors &&
                            <ul id='shipping_errors' className={styles.errors}>
                                {errors.map((x, i) => (
                                    <li key={i}>{x}</li>
                                ))}
                            </ul>
                        }
                        <TextField
                            id='email'
                            name='email'
                            label={inputErrors['email'] || 'Email'}
                            error={Boolean(inputErrors['email'])}
                            type='email'
                            autoComplete='email'
                            value={values.email}
                            onChange={this.handleChange}
                            margin='dense'
                            variant='outlined'
                            fullWidth={true}
                            className={styles.input}
                        />
                        <TextField
                            id='password'
                            name='password'
                            label={inputErrors['password'] || 'Password'}
                            error={Boolean(inputErrors['password'])}
                            type='password'
                            autoComplete='new-password'
                            value={values.password}
                            onChange={this.handleChange}
                            margin='dense'
                            variant='outlined'
                            fullWidth={true}
                            className={styles.input}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color='primary'
                                    name='subscribe_newsletter'
                                    checked={values.subscribe_newsletter}
                                    onChange={this.checkChange}

                                />
                            }
                            label='Keep me up to date on news and exclusive offers'
                            classes={{
                                root: styles.newsletter,
                                label: styles.label
                            }}
                        />
                    </section>
                }
                <section className={styles.section}>
                    <div className={styles.top}>
                        <h6>Shipping Address</h6>
                    </div>
                    {hasSavedAddress &&
                        <div className={styles.switch}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        name='use_saved_address'
                                        checked={useSavedAddress}
                                        onChange={this.useSavedAddressChange}
                                    />
                                }
                                label='Use Saved Address'
                                classes={{
                                    label: styles.label
                                }}
                            />
                            {!useSavedAddress && hasExistingSubscriptions &&
                                <p><Info size={16} className={styles.icon} /> Please note that if you have any active subscriptions, this will update their shipping address.</p>
                            }
                        </div>
                    }
                    <div className={styles.row2}>
                        <div className={styles.cell}>
                            <TextField
                                id='fname'
                                name='fname'
                                label={inputErrors['fname'] || 'First Name'}
                                error={Boolean(inputErrors['fname'])}
                                type='text'
                                autoComplete='given-name'
                                value={values.fname}
                                onChange={this.handleChange}
                                margin='dense'
                                variant='outlined'
                                fullWidth={true}
                                className={styles.input}
                                disabled={useSavedAddress}
                            />
                        </div>
                        <div className={styles.cell}>
                            <TextField
                                id='lname'
                                name='lname'
                                label={inputErrors['lname'] || 'Last Name'}
                                error={Boolean(inputErrors['lname'])}
                                type='text'
                                autoComplete='family-name'
                                value={values.lname}
                                onChange={this.handleChange}
                                margin='dense'
                                variant='outlined'
                                fullWidth={true}
                                className={styles.input}
                                disabled={useSavedAddress}
                            />
                        </div>
                    </div>
                    <TextField
                        id='addressln1'
                        name='addressln1'
                        label={inputErrors['addressln1'] || 'Address'}
                        error={Boolean(inputErrors['addressln1'])}
                        type='text'
                        autoComplete='address-line1'
                        value={values.addressln1}
                        onChange={this.handleChange}
                        margin='dense'
                        variant='outlined'
                        fullWidth={true}
                        className={styles.input}
                        disabled={useSavedAddress}
                    />
                    <TextField
                        id='addressln2'
                        name='addressln2'
                        label={inputErrors['addressln2'] || 'Apartment, suite, etc. (optional)'}
                        error={Boolean(inputErrors['addressln2'])}
                        type='text'
                        autoComplete='address-line2'
                        value={values.addressln2}
                        onChange={this.handleChange}
                        margin='dense'
                        variant='outlined'
                        fullWidth={true}
                        className={styles.input}
                        disabled={useSavedAddress}
                    />
                    <TextField
                        id='city'
                        name='city'
                        label={inputErrors['city'] || 'City'}
                        error={Boolean(inputErrors['city'])}
                        type='text'
                        autoComplete='address-level2'
                        value={values.city}
                        onChange={this.handleChange}
                        margin='dense'
                        variant='outlined'
                        fullWidth={true}
                        className={styles.input}
                        disabled={useSavedAddress}
                    />
                    <div className={styles.row2}>
                        <div className={styles.cell}>
                            <NativeSelect
                                id='state'
                                name='state'
                                label={inputErrors['state'] || 'State'}
                                error={Boolean(inputErrors['state'])}
                                autoComplete='address-level1'
                                value={values.state}
                                className={styles.input}
                                onChange={this.handleChange}
                                disabled={useSavedAddress}
                            >
                                <option value='' />
                                <option value='AL'>Alabama</option>
                                <option value='AK'>Alaska</option>
                                <option value='AZ'>Arizona</option>
                                <option value='AR'>Arkansas</option>
                                <option value='CA'>California</option>
                                <option value='CO'>Colorado</option>
                                <option value='CT'>Connecticut</option>
                                <option value='DE'>Delaware</option>
                                <option value='DC'>District Of Columbia</option>
                                <option value='FL'>Florida</option>
                                <option value='GA'>Georgia</option>
                                <option value='HI'>Hawaii</option>
                                <option value='ID'>Idaho</option>
                                <option value='IL'>Illinois</option>
                                <option value='IN'>Indiana</option>
                                <option value='IA'>Iowa</option>
                                <option value='KS'>Kansas</option>
                                <option value='KY'>Kentucky</option>
                                <option value='LA'>Louisiana</option>
                                <option value='ME'>Maine</option>
                                <option value='MD'>Maryland</option>
                                <option value='MA'>Massachusetts</option>
                                <option value='MI'>Michigan</option>
                                <option value='MN'>Minnesota</option>
                                <option value='MS'>Mississippi</option>
                                <option value='MO'>Missouri</option>
                                <option value='MT'>Montana</option>
                                <option value='NE'>Nebraska</option>
                                <option value='NV'>Nevada</option>
                                <option value='NH'>New Hampshire</option>
                                <option value='NJ'>New Jersey</option>
                                <option value='NM'>New Mexico</option>
                                <option value='NY'>New York</option>
                                <option value='NC'>North Carolina</option>
                                <option value='ND'>North Dakota</option>
                                <option value='OH'>Ohio</option>
                                <option value='OK'>Oklahoma</option>
                                <option value='OR'>Oregon</option>
                                <option value='PA'>Pennsylvania</option>
                                <option value='RI'>Rhode Island</option>
                                <option value='SC'>South Carolina</option>
                                <option value='SD'>South Dakota</option>
                                <option value='TN'>Tennessee</option>
                                <option value='TX'>Texas</option>
                                <option value='UT'>Utah</option>
                                <option value='VT'>Vermont</option>
                                <option value='VA'>Virginia</option>
                                <option value='WA'>Washington</option>
                                <option value='WV'>West Virginia</option>
                                <option value='WI'>Wisconsin</option>
                                <option value='WY'>Wyoming</option>
                            </NativeSelect>
                        </div>
                        <div className={styles.cell}>
                            <TextField
                                id='postal'
                                name='postal'
                                label={inputErrors['postal'] || 'ZIP Code'}
                                error={Boolean(inputErrors['postal'])}
                                type='text'
                                autoComplete='postal-code'
                                value={values.postal}
                                onChange={this.handleChange}
                                margin='dense'
                                variant='outlined'
                                fullWidth={true}
                                className={styles.input}
                                disabled={useSavedAddress}
                            />
                        </div>
                    </div>
                </section>
                <nav className={styles.nav}>
                    <Button
                        type='button'
                        disabled={loading}
                        onClick={this.onBack}
                    >
                        Back
                    </Button>
                    <Button
                        type='submit'
                        variant='contained'
                        color='primary'
                        disabled={loading}
                        className={styles.submit}
                    >
                        {loading ?
                            <CircularProgress
                                color='inherit'
                                size={20}
                                className={styles.loading}
                            /> :
                            'NEXT'
                        }
                    </Button>
                </nav>
            </form>
        );
    }
}

ShippingInfoStep.props = {
    step: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    prevStep: PropTypes.func.isRequired,
    submitStep: PropTypes.func.isRequired,
    formData: PropTypes.object,
    redirect: PropTypes.object
};

const mapState = (state) => ({
    auth: state.auth,
    account: state.account
});

const mapDispatch = {
    setCheckoutData: CheckoutActions.setCheckoutData,
    signup: AccountActions.signup,
    setShippingAddress: AccountActions.setShippingAddress
};

export default connect(mapState, mapDispatch)(ShippingInfoStep);

import * as Types from '../constants/action-types';
import API from '../api/roselium-api';

export function setCheckoutData({ step, metadata }) {
    return {
        type: Types.SET_CHECKOUT_DATA,
        data: {
            step,
            metadata
        }
    };
}

export function clearCheckoutData() {
    return { type: Types.CLEAR_CHECKOUT_DATA };
}

export function getCoupon({ id }) {
    return async (dispatch, getState) => {
        return await API.getCoupon({ id });
    };
}

export function createSubscription({ plan, token, coupon, metadata }) {
    return async (dispatch, getState) => {
        return await API.createSubscription(
            {
                plan_id: plan.id,
                source_token: token ? token.id : null,
                coupon_id: coupon ? coupon.id : null,
                metadata
            }
        );
    };
}

export function subscriptionPayment({ subscription, token }) {
    return async (dispatch, getState) => {
        return await API.subscriptionPayment(
            {
                subscription_id: subscription,
                source_token: token ? token.id : null
            }
        );
    };
}
